import React from 'react'

import MinMaxKnobs from 'components/MinMaxKnobs';


let GlitchBoard = (props) => {
  const {
    barToggleDetails,
    minBarHeightKnobDetails,
    maxBarHeightKnobDetails,
    multiLineToggleDetails,
    singleLineToggleDetails,
    pixelSortToggleDetails,
    glitchBoardContainerRef
  } = props;

  return (
    <div className={"GlitchBoard"}>
      <div className="GlitchInput">
        <button className={`ToggleButton ${barToggleDetails['value'] ? 'active' : 'ready'}`} onClick={()=>barToggleDetails['setter'](old => !old)}/>
        <div>Bars [{barToggleDetails['value'] ? 'enabled' : 'disabled'}]</div>
      </div>
      <MinMaxKnobs
        name={'Bar Height'}
        units={'px'}
        enabled={barToggleDetails['value']}
        min={minBarHeightKnobDetails['min']}
        max={minBarHeightKnobDetails['max']}
        step={minBarHeightKnobDetails['step']}
        lower={minBarHeightKnobDetails['value']}
        setLower={minBarHeightKnobDetails['setter']}
        upper={maxBarHeightKnobDetails['value']}
        setUpper={maxBarHeightKnobDetails['setter']}
        rootParent={glitchBoardContainerRef}
      />
      <div className="GlitchInput">
        <button className={`ToggleButton ${multiLineToggleDetails['value'] ? 'active' : 'ready'}`} onClick={()=>multiLineToggleDetails['setter'](old => !old)}/>
        <div>Multi-line [{multiLineToggleDetails['value'] ? 'enabled' : 'disabled'}]</div>
      </div>
      <div className="GlitchInput">
        <button className={`ToggleButton ${singleLineToggleDetails['value'] ? 'active' : 'ready'}`} onClick={()=>singleLineToggleDetails['setter'](old => !old)}/>
        <div>Single Line [{singleLineToggleDetails['value'] ? 'enabled' : 'disabled'}]</div>
      </div>
      <br/>
      <div className="GlitchInput">
        <button className={`ToggleButton ${pixelSortToggleDetails['value'] ? 'active' : 'ready'}`} onClick={()=>pixelSortToggleDetails['setter'](old => !old)}/>
        <div>Pixel Sort [{pixelSortToggleDetails['value'] ? 'enabled' : 'disabled'}]</div>
      </div>
    </div>
  )
}

export default GlitchBoard;