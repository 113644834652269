import React from 'react'

import InputKnob from 'components/InputKnob'

let MinMaxKnobs = (props) => {
  const {name, units, enabled, min, max, step, lower, setLower, upper, setUpper, rootParent} = props;

  const handleUpperChange = (newUpper) => {
    if (newUpper < lower){
      setLower(newUpper)
    }
    setUpper(newUpper)
  }

  const handleLowerChange = (newLower) => {
    if (newLower > upper){
      setUpper(newLower)
    }
    setLower(newLower)
  }

  return (
    <div 
    className={'MinMaxKnobs'}
    >
      <div className="GlitchInput pad-top">
        <InputKnob enabled={enabled} min={min} max={max} step={step} setValue={handleLowerChange} value={lower} rootParent={rootParent}/>
        <div>Min {name}: {lower}{units}</div>
      </div>
      <div className="GlitchInput pad-top">
        <InputKnob enabled={enabled} min={min} max={max} step={step} setValue={handleUpperChange} value={upper} rootParent={rootParent}/>
        <div>Max {name}: {upper}{units}</div>
      </div>
    </div>
  )
}

export default MinMaxKnobs;