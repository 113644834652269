import axios from 'axios';

const mosh = (image, setImage, setImageFile, hideMoshMessage, options) => {
  console.log('mosh individual');

  const formData = new FormData();

  // Update the formData object
  formData.append(
    "image_file",
    image,
    image.name
  );

  formData.append(
    "glitches",
    JSON.stringify(options)
  );

  // Details of the uploaded file
  console.log(image);

  // Request made to the backend api
  // Send formData object
  axios.post(
    `${process.env.REACT_APP_API_DOMAIN}/mosh`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
        'accept': 'application/json'
      }
    }
  ).then(res => {
    console.log("here!");
    const dataURL = 'data:image/png;base64,'+res.data.encoded_img
    // Display image
    setImage(dataURL);

    // Save new image data
    fetch(dataURL)
    .then(res => res.blob())
    .then(blob => setImageFile(blob))
    .finally(() => hideMoshMessage())
  });

}

const mosh_multi = (imageDataArray) => {
  console.log('mosh multiple');
  console.log(imageDataArray);
}

export {mosh, mosh_multi}