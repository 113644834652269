import React, {useCallback} from 'react'
import {useDropzone} from 'react-dropzone'

import LogoImage from 'media/Glitched_Image_Icon.png';

let DragDrop = (props) => {
  const {setImage, image, setImageFile, readyMoshButton} = props;

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      console.log(file)
      setImageFile(file)
      const reader = new FileReader()
      reader.onabort = () => console.log('file reading was aborted')
      reader.onerror = () => console.log('file reading has failed')
      reader.onload = () => {
        setImage(reader.result);
        readyMoshButton();
      }
      reader.readAsDataURL(file);
    })
    
  }, [setImage, setImageFile, readyMoshButton])

  const {getRootProps, getInputProps} = useDropzone({
    onDrop:onDrop,
    accept: {
     'image/png': ['.png'],
     'image/jpeg': ['.jpg', '.jpeg'],
    },
    maxFiles:1
  })

  return (
    <>
    <div className={`DragDrop ${image == null ? '': 'hide'}`} {...getRootProps()}>
      <p id='drag-message-top'>Drag your photo here</p>
      <p id='drag-message-right'>Drag your photo here</p>
      <p id='drag-message-left'>Drag your photo here</p>
      <img id='GlitchedLogo' src={LogoImage} alt='Glitched Logo'></img>
      <p id='drag-message-bottom'>Drag your photo here</p>
      <input {...getInputProps()} />
    </div>
    <div className={`DragDropImageContainer ${image != null ? '': 'hide'}`}>
      <img className="DragDropImage" src={image} alt='Pic to mosh'></img>
    </div>
    </>
  )
}

export default DragDrop;