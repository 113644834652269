import React, {useState, useEffect, useRef, useLayoutEffect} from 'react'

let InputKnob = (props) => {
  const {enabled, max, min, step, value, setValue, rootParent} = props;

  const elementRef = useRef();
  const [size, setSize] = useState(0)

  useLayoutEffect(() => {
    if (elementRef.current) {
      setSize(elementRef.current.offsetWidth);
    }
  }, []);

  const valueToXAndY = (value, size) => {
    let hypotenuse = size/2 - 15;
    let locAngle = Math.round(((value - min)/max)*360);
    let rad = (locAngle * Math.PI) / 180;
    let out_y = Math.cos(rad) * hypotenuse * -1;
    let out_x = Math.sin(rad) * hypotenuse;
    return [out_x, out_y]
  }

  const [hoverActive, setHoverActive] = useState(false);
  const [x, setX] = useState(0)
  const [y, setY] = useState(0)

  useEffect(() => {
    let xy = valueToXAndY(value, size)
    setX(xy[0])
    setY(xy[1])
  })

  const handleMouseMove = e => {
    if(hoverActive && enabled){
      let knob = e.target;
      let center_x = (knob.offsetWidth / 2) + knob.offsetLeft + rootParent.current.offsetLeft
      let center_y = (knob.offsetHeight / 2) + knob.offsetTop + rootParent.current.offsetTop
      let pos_x = e.pageX
      let pos_y = e.pageY
      let delta_y =  center_y - pos_y
      let delta_x = center_x - pos_x
      let locAngle = Math.atan2(delta_y, delta_x) * (180 / Math.PI) // Calculate angle between circle center and mouse pos
      locAngle -= 90
      if (locAngle < 0){
        locAngle = 360 + locAngle //Always show angle positive
      }
      locAngle = Math.round(locAngle)
      let percent = locAngle/360;
      let locVal = Math.floor(((max * percent) + min)/step)*step;
      setValue(locVal);
    }
  }

  return (
    <div
    ref={elementRef}
    className={`InputKnob ${enabled ? 'ready' : 'disabled'}`}
    style={{backgroundPosition: `${x}px ${y}px`}}
    onMouseDown={()=>setHoverActive(true)}
    onMouseUp={()=>setHoverActive(false)}
    onMouseOut={()=>setHoverActive(false)}
    onMouseOver={e=>setHoverActive( e.buttons === 1 ? true : false)}
    onMouseMove={e=>handleMouseMove(e)}
    />
  )
}

export default InputKnob;