import React, {useState, useRef} from 'react'

import DragDrop from 'components/DragDrop';
import GlitchBoard from 'components/GlitchBoard';

import { mosh } from 'util/network';

const HomePage = props => {
  // TODO: Update this to state looking at keyboard interaction in the future

  const [generateButtonReady, setGenerateButtonReady] = useState(false)
  const [moshButtonMessageVisible, setMoshButtonMessageVisible] = useState(false)
  const [moshMessage, setMoshMessage] = useState("Upload an image first!")

  const [image, setImage] = useState(null)
  const [imageFile, setImageFile] = useState(null)

  const barHeightMin = 5;
  const barHeightMax = 100;
  const barHeightStep = 5;

  const [barGlitchToggle, setBarGlitchToggle] = useState(false)
  const [minBarHeight, setMinBarHeight] = useState(barHeightMin)
  const [maxBarHeight, setMaxBarHeight] = useState(barHeightMin+barHeightStep)
  const [multiLineToggle, setMultiLineToggle] = useState(false)
  const [singleLineToggle, setSingleLineToggle] = useState(false)

  const [pixelSortToggle, setPixelSortToggle] = useState(false)

  const glitchBoardContainerRef = useRef();

  const readyMoshButton = () => {
    setGenerateButtonReady(true);
    setMoshMessage("Moshing!");
  }

  const hideMoshMessage = () => {
    setMoshButtonMessageVisible(false)
  }

  const moshButtonClickHandler = () => {
    setMoshButtonMessageVisible(true);
    if (generateButtonReady) {
      const options = {}
      if (barGlitchToggle){
        let types = []
        if (multiLineToggle){
          types.push('multi-line')
        }
        if (singleLineToggle){
          types.push('single-line')
        }
        options['bars'] = {minHeight: minBarHeight, maxHeight: maxBarHeight, heightStepSize: barHeightStep, types: types}
      }
      if (pixelSortToggle){
        options['pixel-sort'] = {enabled: true}
      }
      mosh(imageFile, setImage, setImageFile, hideMoshMessage, options)
    } else {
      setTimeout(hideMoshMessage, 3000);
    }
  }

  return (
    <div
      tabIndex="0"
      className="HomePage"
    >
      <div className="HomePageMiddle">
        <div className="LeftPanel" ref={glitchBoardContainerRef}>
          <GlitchBoard
            barToggleDetails={{value: barGlitchToggle, setter: setBarGlitchToggle}}
            minBarHeightKnobDetails={{value: minBarHeight, setter: setMinBarHeight, min: barHeightMin, max: barHeightMax, step: barHeightStep }}
            maxBarHeightKnobDetails={{value: maxBarHeight, setter: setMaxBarHeight, min: barHeightMin, max: barHeightMax, step: barHeightStep }}
            multiLineToggleDetails={{value: multiLineToggle, setter: setMultiLineToggle}}
            singleLineToggleDetails={{value: singleLineToggle, setter: setSingleLineToggle}}
            pixelSortToggleDetails={{value: pixelSortToggle, setter: setPixelSortToggle}}
            glitchBoardContainerRef={glitchBoardContainerRef}
          />
        </div>
        <DragDrop setImage={setImage} image={image} setImageFile={setImageFile} readyMoshButton={readyMoshButton} />
      </div>
      <button
        type="button"
        className={`ClickButton ${generateButtonReady ? 'ready' : ''}`}
        onClick={moshButtonClickHandler}
      >
        ImgaMosh!
      </button>
      <div className={`MoshButtonMessage ${moshButtonMessageVisible ? 'active' : ''}`}>{moshMessage}</div>
    </div>
  );
};

export default HomePage;
